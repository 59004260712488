import React from "react";
import { graphql } from "gatsby";
import "./CaseStudiesList.scss";
import { ImagePass } from "../../Image/ImagePass";
import GatsbyLink from "../../GatsbyLink";
import Pagination from "../../Pagination";
import RenderContent from "../../RenderContent";

export const CaseStudiesList = ({
  posts,
  pageContext,
  pathPrefix,
  categories,
}) => {
  const returnCategoryName = (categoryId, allCategories) => {
    let name;
    allCategories.map((cat) => {
      if (cat.node.wordpress_id === categoryId) {
        name = cat.node.name;
      }
    });
    return name;
  };

  return (
    <section className="case-study-list layout">
      <div className="case-study-list__wrapper">
        <div className="case-study-list__grid">
          {Object.keys(posts).length > 0 &&
            posts?.map((post, index) => {
              return (
                post.node.slug !== "gatsby-demo" && (
                  <GatsbyLink
                    key={index}
                    className="case-study-list__item"
                    to={post.node.path}
                  >
                    <div className="case-study-list__item__image">
                      <ImagePass src={post?.node?.featured_media} />
                    </div>
                    <div className="case-study-list__item__info">
                      <h3 className="case-stidy-list__item__heading">
                        {post.node.title}
                      </h3>
                      {post.node.excerpt && (
                        <RenderContent content={post.node.excerpt} />
                      )}
                    </div>
                  </GatsbyLink>
                )
              );
            })}
        </div>
        <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
      </div>
    </section>
  );
};

export const pageQuery = graphql`
  fragment CaseStudyListFields on wordpress__wp_case_studies {
    id
    case_study_category
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    path
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 670, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
