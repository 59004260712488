import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { Header } from "../components/Acf/Header";
import { CaseStudiesList } from "../components/CaseStudies/CaseStudiesList";
import { decodeEntities } from "../utils/helpers";

const CaseStudiesIndexPage = (props) => {
  const { data, pageContext, location, path } = props;
  const {
    wordpressPost: page = [],
    allWordpressWpCaseStudies,
    categoryFilter,
    categories,
    wordpressCategory,
    wordpressWpSettings,
    siteSettings,
  } = data;
  const { options = null } = siteSettings;
  const { wordpressUrl } = wordpressWpSettings;
  const { title, yoast, acf } = page
    ? page
    : { title: null, yoast: {}, acf: {} };
  const { title: siteTitle } = wordpressWpSettings;
  const { edges: posts } = allWordpressWpCaseStudies;
  const featuredImage =
    wordpressCategory && wordpressCategory.acf.featureImage
      ? wordpressCategory.acf.featureImage
      : "";

  return (
    <>
      <SEO
        title={`${
          yoast.metaTitle
            ? yoast.metaTitle
            : `Case Studies | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      <Header {...acf.layout[0]} />
      <CaseStudiesList
        posts={posts}
        pageContext={pageContext}
        siteMetadata={wordpressWpSettings}
        categories={categories.edges}
        pathPrefix={"/case-studies/"}
      />
    </>
  );
};

export default CaseStudiesIndexPage;

export const pageQuery = graphql`
  query CaseStudyIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    wordpressPost: wordpressPage(slug: { eq: "case-studies" }) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        layout: layout_page {
          __typename
          ...HeaderQuery
        }
      }
    }
    wordpressCategory: wordpressWpCaseStudyCategory(
      slug: { eq: "uncategorised" }
    ) {
      name
      slug
      path
      description
      # acf {
      #   featureImage {
      #     localFile {
      #       childImageSharp {
      #         fluid(maxWidth: 670, quality: 70) {
      #           ...GatsbyImageSharpFluid_noBase64
      #         }
      #       }
      #     }
      #   }
      #   title
      # }
    }
    allWordpressWpCaseStudies(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          content
          title
          ...CaseStudyListFields
        }
      }
    }
    categories: allWordpressWpCaseStudyCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
        }
      }
    }
  }
`;
